import React from "react";

import { WHO_WE_ARE } from "../../../content/who-we-are";

import { useTranslations } from "../../hooks/translations";

import ButtonLink from "../button-link";

const WhoWeAre = () => {
  const t = useTranslations("pages.index.whoWeAre");

  return (
    <section>
      <div className="bp-container">
        <h1>{t.heading}</h1>
        <p className="bp-subheading">{t.description}</p>

        <div className="bp-cards bp-3">
          {WHO_WE_ARE.map(({ key, icon, url }) => {
            const { heading: cardHeading, description: cardDescription, cta } = t[key] || {};
            return (
              <div key={key} className="bp-card">
                <h2>{cardHeading}</h2>
                <div>
                  <img src={icon} width="140" height="140" alt="" />
                </div>
                <p>{cardDescription}</p>
                <ButtonLink className="bp-inverse" to={url}>
                  {cta}
                </ButtonLink>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;
