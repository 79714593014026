import React, { useEffect, useRef } from "react";

import { PARTNERS } from "../../../content/partners";

import { BREAKPOINTS } from "../../global-styles/breakpoints";
import { useTranslations } from "../../hooks/translations";

import ButtonLink from "../button-link";
import Swiper from "../swiper";
import Partner from "../partner";

import styles from "./partners-preview.module.scss";

const PartnersPreview = () => {
  const t = useTranslations("pages.index.partnersPreview");

  const partners = useTranslations("partners");

  const swiperContainer = useRef();

  useEffect(() => {
    // eslint-disable-next-line no-new
    new Swiper(swiperContainer.current, {
      speed: 2000,
      loop: true,
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 10,
      autoplay: {
        disableOnInteraction: false,
        delay: 1000
      },
      breakpoints: {
        [BREAKPOINTS.MD]: {
          slidesPerView: 2,
          slidesPerGroup: 2,
          spaceBetween: 20,
          autoplay: {
            delay: 2000
          }
        },
        [BREAKPOINTS.LG]: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 30,
          autoplay: {
            delay: 3000
          }
        },
        [BREAKPOINTS.XL]: {
          slidesPerView: 4,
          slidesPerGroup: 4,
          spaceBetween: 40,
          autoplay: {
            delay: 4000
          }
        }
      }
    });
  }, []);

  return (
    <section className={styles.section}>
      <div className="bp-container">
        <h1>{t.heading}</h1>
        <div className="swiper-container" ref={swiperContainer}>
          <ul className={`swiper-wrapper ${styles.logos}`}>
            {PARTNERS.map(({ key, logo, url }) => (
              <Partner
                key={key}
                label={partners[key]}
                logo={logo}
                url={url}
                className="swiper-slide"
              />
            ))}
          </ul>
        </div>
        <ButtonLink className="bp-inverse" to="/about/partners">
          {t.allPartners}
        </ButtonLink>
      </div>
    </section>
  );
};

export default PartnersPreview;
