import aboutUsImg from "../src/assets/images/about-us.svg";
import whyItMattersImg from "../src/assets/images/why-it-matters.svg";
import joinUsImg from "../src/assets/images/join-us.svg";

export const WHO_WE_ARE = [
  {
    key: "about",
    icon: aboutUsImg,
    url: "/about/history"
  },
  {
    key: "why",
    icon: whyItMattersImg,
    url: "/about/reports"
  },
  {
    key: "join",
    icon: joinUsImg,
    url: "/register"
  }
];
