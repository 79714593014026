import VolunteerImg from "../src/assets/photos/hero-volunteer.jpg";
import PartnerImg from "../src/assets/photos/hero-partner.jpg";
import DonateImg from "../src/assets/photos/hero-donate.jpg";
import ReportImg from "../src/assets/photos/hero-report.jpg";

export const SLIDES = [
  {
    key: "volunteer",
    img: VolunteerImg
  },
  {
    key: "partner",
    img: PartnerImg
  },
  {
    key: "donate",
    img: DonateImg
  },
  {
    key: "report",
    img: ReportImg
  }
];
