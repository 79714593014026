import React from "react";
import PropTypes from "prop-types";

import styles from "./slide.module.scss";

const Slide = ({ image }) => (
  <div
    className={`swiper-slide swiper-lazy bp-section-alternative bp-background ${styles.container}`}
    data-background={image}
  >
    <div className={`bp-full-screen ${styles.opaque}`} />
    <div className="swiper-lazy-preloader" />
  </div>
);

Slide.propTypes = {
  image: PropTypes.string.isRequired
};

export default Slide;
