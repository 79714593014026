import React, { useEffect, useRef } from "react";

import { SLIDES } from "../../../../content/hero";

import IconLongArrow from "../../../assets/icons/long-arrow.svg";

import { useTranslations } from "../../../hooks/translations";

import ButtonLink from "../../button-link";
import Swiper from "../../swiper";

import Slide from "./slide";

import styles from "./hero.module.scss";

const Hero = () => {
  const t = useTranslations("hero");

  const swiperContainerRef = useRef();
  const swiperPrevSlideRef = useRef();
  const swiperNextSlideRef = useRef();
  const swiperPaginationRef = useRef();

  useEffect(() => {
    // eslint-disable-next-line no-new
    new Swiper(swiperContainerRef.current, {
      preloadImages: false,
      loop: true,
      autoplay: {
        delay: 10000
      },
      speed: 1000,
      lazy: {
        loadPrevNext: true
      },
      navigation: {
        nextEl: swiperNextSlideRef.current,
        prevEl: swiperPrevSlideRef.current
      },
      pagination: {
        el: swiperPaginationRef.current,
        type: "bullets",
        bulletClass: styles.slideBullet,
        bulletActiveClass: styles.slideBulletActive,
        clickable: true
      }
    });
  }, []);

  return (
    <div
      className={`swiper-container bp-section-alternative ${styles.container}`}
      ref={swiperContainerRef}
    >
      <div className={`swiper-wrapper bp-full-screen ${styles.slidesWrapper}`}>
        {SLIDES.map(({ key, img }) => (
          <Slide key={key} image={img} />
        ))}
      </div>

      <div className={styles.margin}>
        <div className={`bp-container ${styles.main}`}>
          <h1 className={styles.heading}>
            {t.heading1} <em>{t.headingIndependent}</em> {t.heading2}
          </h1>
          <p className={styles.subheading}>{t.description}</p>

          <ButtonLink className={`bp-standard ${styles.cta}`} to="/register">
            {t.cta}
          </ButtonLink>

          <ButtonLink className={`${styles.cta} ${styles.donateCta}`} to="/donate">
            {t.cta2}
          </ButtonLink>
        </div>
      </div>

      <div className={`${styles.pagination}`} ref={swiperPaginationRef} />

      <IconLongArrow className={`bp-stroke bp-2 ${styles.longArrow}`} />
    </div>
  );
};

export default Hero;
